// extracted by mini-css-extract-plugin
export var banner_whySkillElevator = "whyskillelevator-module--banner_whySkillElevator--9zdTG";
export var heightFull = "whyskillelevator-module--heightFull--nVjSy";
export var TextCenterBoldWhite = "whyskillelevator-module--TextCenterBoldWhite--VSBdW";
export var paddingTop100 = "whyskillelevator-module--paddingTop100--brmg2";
export var TextLeftBold = "whyskillelevator-module--TextLeftBold--mufM4";
export var TextJustify = "whyskillelevator-module--TextJustify--k7jcp";
export var what_is_skill_elevator_platform = "whyskillelevator-module--what_is_skill_elevator_platform--r4vha";
export var bussinessLaboratoryJobRoles = "whyskillelevator-module--bussinessLaboratoryJobRoles--gmyx2";
export var aboutHeading = "whyskillelevator-module--aboutHeading---je4a";
export var why_skill_elevator = "whyskillelevator-module--why_skill_elevator--2reXy";
export var whyCards = "whyskillelevator-module--whyCards--Gsuhx";
export var aboutTitle = "whyskillelevator-module--aboutTitle--snkma";
export var challengeDetails = "whyskillelevator-module--challengeDetails--9SHww";
export var TextCenterBoldBlackSmall = "whyskillelevator-module--TextCenterBoldBlackSmall--vlhUP";
export var TextLeftBlackSmall = "whyskillelevator-module--TextLeftBlackSmall--ioupS";
export var oltag = "whyskillelevator-module--oltag--VisRR";
export var cards = "whyskillelevator-module--cards--BktzG";
export var bottomcard = "whyskillelevator-module--bottomcard--KxeW5";
export var linear_gradient = "whyskillelevator-module--linear_gradient--RjaY4";
export var signup_btn = "whyskillelevator-module--signup_btn--41lJH";