import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header_v1"
import { Icon } from 'react-icons-kit'
import { facebookOfficial } from 'react-icons-kit/fa/facebookOfficial'
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'
import { youtubePlay } from 'react-icons-kit/fa/youtubePlay'
import { twitter } from 'react-icons-kit/fa/twitter'
import { Row, Container, Col } from "react-bootstrap";
// import * as Styles from "../style/home.module.scss";
import *as Styles from "../style/whyskillelevator.module.scss"
//Components Import
import CallToAction from "../components/button/index";
//Images and Svg Import
import logoSVG from "../assets/images/Logo.png";


const WhySkillElevatorPage = () => {
  
  const whyItems = [
    {
      heading: "Providing Practical Exposure",
      color: '#47319E',
      details: "Practical exposure, equivalent to 5 years of experience, to the work has been provided in online."
    },
    {
      heading: "Work exposure for all finance job roles",
      color: '#00A8EA',
      details: "The top job roles in the Accounts and finance department have been defined, and work exposure has been mapped so that one can get exposure to different jobs."
    },
    {
      heading: "Freshers to experienced finance professionals",
      color: '#FF3330',
      details: "The modules are designed in such a way that each undergrad/fresher can claim to be an experienced accountant or finance worker"
    },
    
  ]
  return (
    <Layout>
      <Seo title="Why Skill Elevator" />
      <div id="Home" className={Styles.banner_whySkillElevator}>
        <Header />
        <hr style={{ border: "1px solid #FFFFFF", height: "0.005em", color: "#ffffff", backgroundColor: "#ffffff" }} />
        <Row className={Styles.heightFull}>
        <Col md={12} >
              <div className={`${Styles.TextCenterBoldWhite} ${Styles.paddingTop100}`}  >
              The best platform <br />for commerce skilling & job enhancement
              </div>
            </Col>
        </Row>
      </div>
      
      
      <div >
        <Container fluid>
          <Row className={Styles.heightFull} style={{ marginTop: "-10px" }}>
            <Col md={2}></Col>
            <Col md={5} >
              <div className={`${Styles.TextLeftBold} ${Styles.paddingTop100}`}>
              What is Skill-Elevator Platform?
              </div>
              <div className={Styles.TextJustify} style={{width: '90%'}}>
              Skill Elevator is India's first web platform to provide complete Industry and Job experience in virtual environment in accounting and finance filed. Under this platform, all required accounting, tax, statutory filing and other business works have been created as a practical modules
with actual bills, transactions, filings, procedural documents etc which are going to be used in while working in our modules. Skill elevator modules are not theory programmes but it is actual job works that are created in virtual companies where one has to work to gain actual job or industry experience. All modules are supported by theory and video tutor to facilitate the student understand the module in better way. The objective of Skill elevator is to enhance skill and employment for Commerce and Business students. 
              </div>
            </Col>
            <Col md={4} className={Styles.what_is_skill_elevator_platform}></Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </div>

      <div id="Working modules" className={Styles.bussinessLaboratoryJobRoles} 
      style={{paddingBottom: 0}}>
        <Container fluid>
        <h2 className={Styles.aboutHeading} style={{ marginBottom: '-55px' }}>Why Skill-Elevator?</h2>
          <Row className={Styles.heightFull}>
            <Col md={2}></Col>
            <Col md={4} className={Styles.why_skill_elevator}></Col>
            <Col md={4}  className={Styles.cards}>
            {whyItems.map((why) => (
                <div className={Styles.whyCards} style={{borderLeftColor: why.color,
                marginTop: 12, borderLeftWidth: 5}}>
                      <div>
                      <p className={Styles.aboutTitle} style={{ marginBottom: 5, color: why.color }}>{why.heading}</p>
                      <p className={Styles.challengeDetails}>{why.details}</p>
                    </div>
                </div>
            ))}
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
      
      <div>
        <Container fluid className={Styles.linear_gradient}>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <div className={Styles.bottomcard}>
                <p className={`${Styles.TextCenterBoldBlackSmall}`} style={{paddingTop: 40}} >Who should use Skill-Elevator Platform</p>
                <hr />
                <div className={`${Styles.TextLeftBlackSmall}`}  >
                  <ol type="1" className={Styles.oltag}>
                    <li style={{paddingBottom: 10}}> Under Graduates  - B.Com., BBA - 1st year/2nd year/3rd year</li>
                    <li style={{paddingBottom: 10}}> Commerce and Management Graduates (B.Com, BBM, BBA pass outs)</li>
                    <li style={{paddingBottom: 10}}> Postgraduates - M.Com, MBA</li>
                    <li style={{paddingBottom: 10}}> Any Accountant working in a company and looking for growth in their career </li>
                  </ol>
                </div>
                <div className={Styles.signup_btn}>
                  <CallToAction title="SIGNUP NOW" size="xl" />
                </div>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
 
    </Layout>
  )
}
export default WhySkillElevatorPage